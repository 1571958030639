/* Base styles for links */
a {
    color: inherit;
    text-decoration: none; /* This will remove the underline from all links */
    font-family: inherit; /* This ensures the link uses the same font as the rest of the page */
}

/* Styles for .item1 */
.item1 {
    background: rgba(255, 255, 255, 0.993);
    color: rgb(7, 7, 7);
    /* #d4a373 */
    margin: 1px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Aligns the item to the left */
    flex: 1; /* Allows the item to grow if necessary */
}

/* Styles for .item2 */
.item2 {
    background: rgba(255, 255, 255, 0.993);
    color: rgb(7, 7, 7);
    margin: 1px;
    font-size: 15px;
    white-space: nowrap; /* prevents the text from wrapping to the next line */
    overflow: hidden; /* keeps the content from overflowing the `.item2` div */
    text-overflow: ellipsis; /* adds an ellipsis to signify clipped text */
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Aligns the item to the right */
    flex: 1; /* Allows the item to grow if necessary */
    text-align: right; /* Aligns the text to the right within the item */
}

/* Styles for .item3, .item4, and .item5 */
.item3, .item4, .item5 {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item3 {
    background: #292a30;
}

.item4 {
    background: #25252b;
}

.item5 {
    background: #202025;
}

/* Hover effects for .item3, .item4, and .item5 */
.item3:hover, .item4:hover, .item5:hover {
    transition: 0.4s; /* Applies to all hover states */
}

.item3:hover {
    background-color: #000000;
}

.item4:hover {
    background-color: #ffffff;
}

.item5:hover {
    background-color: #da0e0e;
}

.footer {
    /* background-color: #8B4513;  */
    /* Gold brown color */
    /* color: #FFFFFF;  */
    /* White color */
    display: flex;
    align-items: center; /* Vertically aligns children to the middle */
    justify-content: center; /* Horizontally centers items if needed */
    /* Other styles for the footer */
}

/* Styles for .footer-container */
.footer-container {
    display: flex;
    width: 70%;
    justify-content: center; /* This will center all the child elements */
    align-items: center; /* This vertically centers the child elements */
    margin-left: auto; /* These two margins */
    margin-right: auto; /* center the footer-container itself */
    /* padding-bottom: 20px;  */
    /* Adjust the value as needed */
    text-align: center;
    position: relative;
    padding-bottom: 60px;
}


.footer-container{

}
.fixed-bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
}
/* Responsive font sizes for different devices */
@media only screen and (max-width: 600px) {
    /* ... */
}

/* ... other media queries ... */

/* Apply consistent font-size adjustments across the media queries */
/* @media only screen and (min-width: 600px),
       only screen and (min-width: 768px),
       only screen and (min-width: 992px),
       only screen and (min-width: 1200px) {
    .item1, .item2 {
        font-size: 15px; 
    }
    .item3, .item4, .item5 {
        font-size: 12px; 
    }
} */

@media (max-width: 992px) {
    .item1, .item2 {
        font-size: 0.45rem;
    }
  }
  
@media (max-width: 768px) {
    .display-1 {
      font-size: 0.40rem;
      font-size: calc( 2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20)));
      line-height: calc( 1.1 * (2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20))));
    }
    .display-2 {
      font-size: 2.4rem;
      font-size: calc( 1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
      line-height: calc( 1.3 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20))));
    }
    .display-4 {
      font-size: 0.88rem;
      font-size: calc( 1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20)));
      line-height: calc( 1.4 * (1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20))));
    }
    .display-5 {
      font-size: 1.6rem;
      font-size: calc( 1.35rem + (2 - 1.35) * ((100vw - 20rem) / (48 - 20)));
      line-height: calc( 1.4 * (1.35rem + (2 - 1.35) * ((100vw - 20rem) / (48 - 20))));
    }
    .item1, .item2 {
      font-size: 0.45rem;
      /* font-size: calc( 1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20))); */
      line-height: calc( 1.4 * (1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20))));
    }
  }