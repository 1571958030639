/* header.css */

/* Define CSS variables for easy management of font and size */
:root {
  --header-font-family: 'Arial', sans-serif;
  /* Default font, you can change it later */
  --header-font-size: 1.2rem;
  /* Default size, you can change it later */
  --nav-link-font-size: 1rem;
  /* Slightly smaller than the brand for better hierarchy */
}

.navbar {
  background-color: transparent !important;
  /* Set background to transparent */
  /* Other styles for the navbar */
  
}


/* Custom styles for the navbar brand (logo/name) */
.navbar-brand {
  font-family: var(--header-font-family);
  font-size: var(--header-font-size);
  font-weight: bold;
  /* Make the font bold */
  /* Adjust the color for visibility against the light background */
  color: #ffffff !important;
  /* Dark color for better contrast */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  /* Text shadow for readability */

}

/* Custom styles for the navbar links */
.nav-link {
  font-family: var(--header-font-family);
  font-size: var(--nav-link-font-size);
  font-weight: bold;
  /* Make the font bold */
  /* Adjust the color for visibility against the light background */
  color: #ffffff !important;
  /* Ensure the color is white */
  /* color: #333 !important;  */
  /* Dark color for better contrast */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* Text shadow for readability */
}




.navbar-light .navbar-toggler:focus {
  outline: none;
  outline-color: rgb(255, 255, 255);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Ensure the nav links are on the right-hand side for larger screens */
.navbar-collapse {
  justify-content: flex-end;
  
}

/* Custom styles for the toggle button (hamburger menu) */
.navbar-toggler {
  border-color: rgb(255, 255, 255);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Shadow added here */

  /* background-color: #343a40;  */
  /* Example: a dark background color */
  /* Make the border semi-transparent */
}

.navbar-toggler-icon {
  background-image: none !important;
  background-color: white;
  height: 2px;
  width: 25px;
  margin: 10px 0;
  position: relative;
  transition: all 0.35s ease-out;
  transform-origin: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Shadow added here */
}

.navbar-toggler-icon::before {
  display: block;
  background-color: white;
  height: 2px;
  content: "";
  position: relative;
  top: -6px;
  transition: all 0.15s ease-out; /* Taken down to hide quicker */
  transform-origin: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Shadow added here */
}

.navbar-toggler-icon::after {
  display: block;
  background-color: white;
  height: 2px;
  content: "";
  position: relative;
  top: 4px;
  transition: all 0.35s ease-out;
  transform-origin: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Shadow added here */
}

.navbar-dark .navbar-toggler-icon,
.navbar-dark .navbar-toggler-icon::before,
.navbar-dark .navbar-toggler-icon::after {
  background-color: var(--bs-gray-100);
}

/* .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  transform: rotate(45deg);
} */

/* .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
  opacity: 0;
} */

/* .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
  transform: rotate(-90deg) translateX(7px);
} */

/* .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
} */
/* 
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");

} */

/* If the toggler has a custom class, replace '.navbar-light .navbar-toggler' with your custom class selector */

/* Make the navbar links full width on smaller screens */
@media (max-width: 991px) {
  .nav-link {
    width: 100%;
    text-align: right;

    /* Align to the right if that's your preference */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /* Text shadow for readability */

  }
}