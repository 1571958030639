/* News.css */

.half-rule {
  margin-left: 0;
  height: 10px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  background-color: #d4a373;
}

.separator {
  height: 10px;
  /* Height of the separator line */
  width: 100%;
  /* Full container width */
  background-color: #ffffff;
  /* Light grey color, you can change it as needed */
  margin: 2rem 0;
  /* Spacing above and below the separator */
}


.fixed-width-card {
  width: 100%;
  margin: 1rem auto; /* Center the card and add vertical spacing */
  border-radius: 20px; /* Rounded corners for the card */
  border: 1px solid #d4a373; /* Gold brown border color */
}

/* Add bottom margin to all cards except the last child to maintain consistent spacing */
.fixed-width-card:not(:last-child) {
  margin-bottom: 2rem; /* Space between cards */
}

/* Styles for the card image */
.fixed-width-card .card-img-top {
  width: 100%;
  height: auto;
  display: block;
  border-top-left-radius: 20px; /* Rounded corners for the top-left of the image */
  border-top-right-radius: 20px; /* Rounded corners for the top-right of the image */
}

/* Styles for the horizontal divider in the card */
.custom-card-divider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1;
  border-top: 1px solid #d4a373; /* Light grey divider line */
}



.aspect-ratio-16-9 {
  position: relative;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.aspect-ratio-16-9 .card-img-top {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* custom.css file */
.custom-card-corner {
  border-radius: 20px;
  /* Replace with the radius you want */
}


.card-date {
  /* font-size: var(--card-font-size);  */
  /* Use the custom property */
  font-size: 1rem;
  /* Adjust the font size as needed */
  font-style: italic;
  margin-bottom: 1rem;
  /* Add some space below the date */
  color: #333333;
  /* Set the color of the date text, change as needed */
  font-weight: normal;
  /* Make the date bold, optional */
  text-align: right !important;
}

.card-date-small {
  font-size: 0.8rem;
}

.card-date-large {
  font-size: 1.2rem;
}

.card-topic {
  font-size: 0.8em;
  font-weight: bold;
  color: #d4a373; /* Bootstrap primary color */
  margin-bottom: 0.5em;
}

.card-footer {
  background-color: transparent; /* Ensure no background color */
  padding-left: 0; /* Align content to the left */
}

.card-topic {
  font-size: 0.8em;
  color: #d4a373; /* Your specified color */
  margin-bottom: 0.5em;
  margin-right: 0.5em; /* Add some space between the topic and date */
}




:root {
  --card-font-size: 1rem;
  /* Set a default font size */
}

.custom-card-title {
  font-size: 0.9rem;
  /* Set the desired font size */
  font-weight: bold;
  /* Make the date bold, optional */
  text-align: left;

}

.custom-card-text {
  font-size: 0.8rem;
  /* Set the desired font size */
  /* Add any additional styling here */
  text-align: left;

}

/* Style the select element with a custom class */
.form-select-sm.custom-select {
  position: relative; /* Needed to position the pseudo-element */
  border-color: #d4a373; /* Golden brown border */
  background-color: #fff; /* White background */
  color: #000; /* Black text */
  -webkit-appearance: none; /* Removes default chrome and safari style */
  -moz-appearance: none; /* Removes default style Firefox */
  appearance: none; /* Removes default style for IE */
}

/* Create a custom down arrow using a pseudo-element */
.form-select-sm.custom-select::after {
  content: "\25BC"; /* Unicode down arrow */
  color: #d4a373; /* Golden brown color */
  position: absolute; /* Position it within the relative parent */
  top: 50%; /* Center vertically */
  right: 1rem; /* Position from the right */
  transform: translateY(-50%); /* Center it perfectly */
  pointer-events: none; /* Prevent it from interfering with clicks */
}

/* Style for the active state of the filter options */
.form-select-sm.custom-select:focus {
  border-color: #c49a6c; /* Slightly darker shade for focus */
  box-shadow: 0 0 0 0.2rem rgba(212, 163, 115, 0.25); /* Shadow with a transparent golden brown */
}

/* Assuming your pagination is within a container with the class 'my-pagination' */

/* Pagination Index Color, Border, and Rounded Corners with increased specificity */
.my-pagination .pagination .pagination-index {
  color: #d4a373; /* Golden brown color for the text */
  border: 1px solid #d4a373; /* Golden brown border color */
  border-radius: 4px; /* Rounded corners */
  
}

.my-pagination .page-item .page-link {
  color: #d4a373; /* Golden brown color for the text */
  border: 1px solid #d4a373; /* Golden brown border color */
  border-radius: 4px; /* Rounded corners */
}


/* Active Pagination Index with Golden Brown Background and !important to override */
.my-pagination .pagination .pagination-index.active {
  background-color: #d4a373 !important; /* Golden brown background color for the active index */
  color: #fff !important; /* White color for the text to contrast the background */
}

.my-pagination .page-item.active .page-link {
  background-color: #d4a373 !important; /* Golden brown background color for the active index */
  color: #fff !important; /* White color for the text to contrast the background */
}


/* .page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #d4a373 !important;
} */

.edit-button {
  background-color: #ffc107; /* Bootstrap 'warning' color */
  color: #fff; /* White text */
  border: none; /* No border */
}

.edit-button:hover,
.edit-button:focus {
  background-color: #e0a800; /* Darker shade for hover state */
  color: #fff;
}

.delete-button {
  background-color: #dc3545; /* Bootstrap 'danger' color */
  color: #fff; /* White text */
  border: none; /* No border */
}

.delete-button:hover,
.delete-button:focus {
  background-color: #c82333; /* Darker shade for hover state */
  color: #fff;
}

/* Optional: Add a small margin between the buttons */
.button-gap {
  margin-bottom: 0.5rem; /* 8px gap */
}



/* Adjust the top margin for medium devices and up */
@media (min-width: 768px) {
  .fixed-width-card {
    margin: 2rem auto;
    /* Increased top and bottom margin */
  }
}

/* Adjust the top margin for large devices and up */
@media (min-width: 992px) {
  .fixed-width-card {
    margin: 2rem auto;
    /* Consistent with medium devices */
  }
}

/* For extra large screens, you might want to have even more space */
@media (min-width: 1200px) {
  .fixed-width-card {
    margin: 2.5rem auto;
    /* Increased top and bottom margin for extra large screens */
  }
}